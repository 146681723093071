/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var $win = $(window);
        var $smlbp = 768;
        var $doc = $(document);
        var $bd = $('body');
        var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        var moveFlag;
        var $teamList;
        var $title;

        if (('ontouchstart' in window) ||
             (navigator.maxTouchPoints > 0) ||
             (navigator.msMaxTouchPoints > 0)) {
              /* browser with either Touch Events of Pointer Events
                 running on touch-capable device */
                 $bd.addClass('touch-capable');
        }

        if (is_firefox) {
          $bd.addClass('firefox');
        }

        var waitForFinalEvent = (function () {
          var timers = {};
          return function (callback, ms, uniqueId) {
            if (!uniqueId) {
              uniqueId = "Don't call this twice without a uniqueId";
            }
            if (timers[uniqueId]) {
              clearTimeout (timers[uniqueId]);
            }
            timers[uniqueId] = setTimeout(callback, ms);
          };
        })();

        /*
        * Set width check flag
        */

        var flag = $win.width() >= $smlbp ? true : false;

        // console.log('flag = ' + flag);

        $(window).on('resize', function(){

            if ( $win.width() >= $smlbp && !flag === true ) {
              flag = true;
              waitForFinalEvent(function(){
                // console.log('Resize... to large');

              }, 1000, "some unique string");
              // console.log('flag = ' + flag);
            } else if ( $win.width() < $smlbp && !flag === false ) {
              flag = false;
              // console.log('flag = ' + flag);
              waitForFinalEvent(function(){
                // console.log('Resize... to small');

              }, 1000, "some unique string");
            }

        });

		$(document).on('click', 'a.slide-link--production', function (event) {
		    event.preventDefault();

		    $('html, body').animate({
		        scrollTop: $($.attr(this, 'href')).offset().top,
		        easing: 'easeInOutCubic'
		    }, 500);
		});

          // FASTLICK INIT
          if ('addEventListener' in document) {
              document.addEventListener('DOMContentLoaded', function() {
                  FastClick.attach(document.body);
              }, false);
          }

          function playFunction() {
            
            /**
             * Store the transition end event names for convenience.
             */
            var transitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';
            
            /**
             * Trigger the play button states upon clicking.
             */
            $( '.play-btn' ).click(function( e ) {
              
              e.preventDefault();

              $(this).toggleClass('stop');
              
              var pb = $(this);
              var videoInfo = $(this).parents('.flex--video').find('.video');
              var dataMovie = $(this).attr('data-movie');
              var toggledMovie = $('video[data-movie="' + pb.attr('data-movie') + '"]')[0];

              $(videoInfo).add($(this)).toggleClass('video-on');

              toggledMovie.onended = function() {
                setTimeout(function(){
                  videoInfo.removeClass('video-on');
                  pb.removeClass('video-on stop');
                  toggledMovie.load();
                }, 1000)
              };

              toggledMovie.paused ? toggledMovie.play() : toggledMovie.pause();

            });
            
            /**
             * Remove the 'to-play' class upon transition end.
             */
            $( document ).on( transitionEnd, '.to-play', function() {
              $( this ).removeClass( 'to-play' );
            });

          }
          // VIDEO BANNER AND SIDEBAR MEDIA PLAY FUNCTION
          playFunction();

          var searchBtn = $('.search-display-btn');
          var searchInput = $('.banner .search__input');
          var searchForm = $('#searchform');

          if (searchBtn.length) {

            searchBtn.on('click touchstart', function(e) {
              e.stopPropagation();
              e.preventDefault();
              searchInput.toggleClass('display-search');
              searchInput.focus();
            });

            searchInput.on('keyup', function(){
                if ( event.keyCode === 13 ) {
                    searchForm.submit();
                }
            });
              
            $(document).on('click touchstart', function(e){

              e.stopPropagation();

              if ( searchInput.is('.display-search') && !searchInput.is(e.target) && searchInput.has(e.target).length === 0 ) {
                searchInput.removeClass('display-search');
              }

            });

          } // end if

          // grid stuff and b-lazy init

          $(window).on('load', function() {
            // LAZY LOAD BLAZY BANNER IMAGES
            bLazy = new Blazy({
              breakpoints: [{
                	width: 420, // max-width
                	src: 'data-src-sm'
                },
                {
                	width: 992, // max-width
                	src: 'data-src-md'
                },
                {
                	width: 1441, // max-width
                	src: 'data-src-lg'
                }, 
                {
                	width: 5000, // max-width
                	src: 'data-src-xl'
                }]
            });
            
            // ISOTOPE
            $('.news-block-section').isotope({
              layoutMode: 'fitRows',
              useTransform: true,
              speed:800,
              cssEase:'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
              fitRows: {
                gutter:0
              },
              itemSelector: '.col-sm-6',
              percentPosition: true,
              transitionDuration: '0.5s',
              masonry: {
                columnWidth: '.grid-sizer'
              }
            });

            // ISOTOPE
            $('.grid--productions').isotope({
              layoutMode: 'fitRows',
              fitRows: {
                gutter:0
              },
              itemSelector: '.production',
              percentPosition: true,
              transitionDuration: '0.5s',
              masonry: {
                columnWidth: '.grid-sizer'
              }     
            });
            
			$('.fluidbox').fluidbox();

          }); // window on load


          var slickIt = $('.slickify').slick({
            dots:true,
            pauseOnHover:false,
            appendDots:$('.section--slider'),
            speed:1000,
            cssEase:'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
            autoplay: true,
            autoplaySpeed: 8000
          });

          $(window).on('load', function(){
            var currentArticle = slickIt.find('.slick-active .flex');
            setTimeout(function(){
              currentArticle.addClass('desc-appear');
            }, 1000)
            
          });

          slickIt.on('init', function(event, slick){
               var currentArticle = $('.slickify .slick-active').find('.flex');
               currentArticle.addClass('desc-appear');
          })
          .on('afterChange', function(event, slick, currentSlide, nextSlide){
               var article = ($("[data-slick-index='" + currentSlide + "']").find('article'));
               var nextArticle = ($("[data-slick-index='" + nextSlide + "']").find('article'));
               var articleClass = ($("[data-slick-index='" + currentSlide + "']").find('article').attr('class'));

               if ( articleClass.indexOf('content-slide--video') >= 0 ) {
                  // console.log('video slide');
                  // var movie = article.find('iframe');
                  // movie.vimeo('play');
               }

               nextArticle.find('.flex').removeClass('desc-appear');
               article.find('.flex').addClass('desc-appear');
          })
          .on('beforeChange', function(event, slick, currentSlide, nextSlide){
               var article = ($("[data-slick-index='" + currentSlide + "']").find('article'));
               var articleClass = ($("[data-slick-index='" + currentSlide + "']").find('article').attr('class'));
               bLazy.revalidate()
               article.find('.flex').removeClass('desc-appear');
          });

          // $(document).on('click touchstart', '.dropdown li a', function(e){

          //   e.preventDefault();
          //   e.stopPropagation();

          //   bLazy.revalidate();

          //   var sortValue = $(this).attr('data-filter');
          //   var news = $('.news-block-section');
          //   var productions = $('.grid--productions');
          //   var about = $('.filter.about');

          //   var dd = $('ul.filter.about');
          //   var ddd = dd.find('ul.open');

          //   if (news.length) {
          //     news.isotope({ filter:sortValue });
          //   } else if (productions.length) {
          //     productions.isotope({ filter:sortValue });
          //   } else if (about.length && ddd.is('.open')) {
          //     ddd.height(ddd[0].scrollHeight).animate({ height: 0 }, 400, 'easeInOutCubic', function(){
          //       moveFlag = false;
          //       ddd.removeClass('open').removeAttr('style');
          //     });
          //   }

          // });

          // CLOSE .DROPDOWN WHEN CLICKED OUTSIDE OF ELEMENT
          $(document).on('click', function(e){
            
            e.stopPropagation();

            var dd = $('ul.filter');
            var ddd = dd.find('ul.open');

            if ( ddd.is('.open') && !ddd.is(e.target) && ddd.has(e.target).length === 0 ) {
              ddd.height(ddd[0].scrollHeight).animate({ height: 0 }, 400, 'easeInOutCubic', function(){
                moveFlag = false;
                ddd.removeClass('open').removeAttr('style');
              });
            }

          });

          // $('.dropdown li a').on('touchend', function(e){

          //   e.preventDefault();
          //   var dd = $('ul.filter.about');
          //   var ddd = dd.find('ul.open');

          //   setTimeout(function(){

          //     ddd.height(ddd[0].scrollHeight).animate({ height: 0 }, 400, 'easeInOutCubic', function(){
          //       moveFlag = false;
          //       ddd.removeClass('open').removeAttr('style');
          //     });

          //   }, 300);

          // });

          // ISOTOPE FILTER BUTTONS -- this uses dropdown li as primary li element is * selector
          $(document).on('click touchend', '.list--categories a, .list--productions a, .dropdown:not(.archive) li a', function(e) {
            
            e.preventDefault();
            // e.stopPropagation();

            var sortValue = $(this).attr('data-filter');
            var news = $('.news-block-section');
            var productions = $('.grid--productions');
            var about = $('.filter.about');

            var dd = $('ul.filter.about');
            var ddd = dd.find('ul.open');

            if (news.length) { // NEWS PAGE
              news.isotope({ filter:sortValue });
            } else if (productions.length) { // PRODUCTIONS PAGE
              productions.isotope({ filter:sortValue });
            } else if (about.length && ddd.is('.open')) { // ABOUT PAGE
                
                e.stopPropagation();

                setTimeout(function(){

                  ddd.height(ddd[0].scrollHeight).animate({ height: 0 }, 400, 'easeInOutCubic', function(){
                    moveFlag = false;
                    ddd.removeClass('open').removeAttr('style');
                  });

                }, 300);

            }

          });

          $(window).on('load', function() {

            $teamList = $('#teamfilter');
            $teamsubList = $teamList.find('ul li');
            $title = $('#teamfilter .title').parent('li');

            if ( $win.width() < $smlbp ) {

                $teamsubList.addClass('back-to-ul');

                var l = $('#teamfilter li.active a'); // the anchor link

                var lT = l.html();
                
                if ( $title.parents('#teamfilter').length > 0 ) {
                  
                  $('.title').html(lT);

                }

            } else if ( $win.width() >= $smlbp ) {

                
                // hide the title li as we don't need it here
                // $title.hide();
                // // add the relevant styling classes
                // $teamList.addClass('list list--staff').removeClass('dropdown filter about visible-xs');
                // $teamsubList.prependTo($teamList);
                $teamsubList.addClass('back-to-ul');

                $title.hide();
                $teamList.addClass('list list--staff').removeClass('dropdown filter about visible-xs');
                var $subList = $teamList.find('ul li a').parent('li').unwrap('ul');
                $subList.removeAttr('style').appendTo($teamList);
                // $subList.find('.needsclick').addClass('red-slide-link');

                // var innerUL = $teamList.find('li ul li');
                // var innerLI = $innerUL.find('li');

                // innerUL.appendTo($teamList);

            }

          })

          $(window).on('resize', function() {

            $teamList = $('#teamfilter');
            $teamsubList = $teamList.find('ul li');
            $teamsubAnchors = $teamsubList.find('.needsclick');
            $win = $(window);
            $title = $('#teamfilter .title').parent('li');

            if ( $win.width() < $smlbp && !$teamList.is('.dropdown') ) {
              
              $title.show();

              $('.back-to-ul').removeAttr('style');
              var btu = $('.back-to-ul').wrapAll('<ul class="innerul"></ul>');
              $('.innerul').appendTo($title);

              $teamList
              .addClass('dropdown filter about')
              .removeClass('list list--staff');

              // alert('doing function unenlarge');
              // $title.show();
              // $visLI.wrap('<ul class="open"></ul>');
              
              // $teamList.find('.red-slide-link').removeClass('red-slide-link');

            } else if ( $win.width() >= $smlbp && !$teamList.is('.list--staff') ) {

              // $title.hide();

              // $teamList
              // .addClass('list list--staff')
              // .removeClass('dropdown filter about visible-xs');

              // var needclick = $teamsubList.find('.needsclick').addClass('red-slide-link').prependTo($teamList);

              // alert('doing function enlarge');
              $title.hide();
              $teamList.addClass('list list--staff').removeClass('dropdown filter about visible-xs');
              var $subList = $teamList.find('ul li a').addClass('red-slide-link').parent('li').unwrap('ul');
              $subList.appendTo($teamList);
              $subList.find('.needsclick').addClass('red-slide-link');

            }

          })

          // ADD ACTIVE CLASSES TO STAFF MENU AND ALPHABETICAL PRODUCTIONS MENU And DropDowns
          $(document).on('click touchstart', '.list--staff li a, .list--productions li a, .dropdown:not(.archive) ul li a', function(e){

            var $title = $('.title');
            
            e.preventDefault();
            e.stopPropagation();

            var l = $(this); // the anchor link

            var lT = l.html();
            
            if ( $title.parents('#teamfilter').length > 0 ) {
              
              $title.html(lT);

            }

            // console.log(lT);
            
            var $linkdata = l.parent('li').data();
            var $staffList = $('.list--staff');

            var toggledDescription = $('p[data-member="' + l.attr('data-member') + '"]');
            var toggledLink = l.parent();

            var $aboutFilter = ('.filter.about');

            l.parent().addClass('active').siblings().removeClass('active');

            if ( l.parents('.list--staff').length === 1 ) {

              toggledLink.hide()
              .prependTo($staffList)
              .fadeIn(1000);

            } else if ( l.parents('.filter.about').length === 1 ) {
              toggledLink.hide()
              .prependTo('.filter.about ul')
              .show();
            }

            toggledDescription.addClass('active').removeClass('inactive').siblings().addClass('inactive').removeClass('active');

          });
          
        // NEWS DROPDOWN SELECT ARCHIVE
        $(document).on('click touchstart', '.dropdown .title', function(e){
            e.preventDefault();
            
            var $dropdownUL = $(this).next('ul');
            
            if ( $dropdownUL.is('.open') ) {
              $dropdownUL.height($dropdownUL[0].scrollHeight).animate({ height: 0 }, 400, 'easeInOutCubic', function(){
                moveFlag = false;
                $dropdownUL.removeClass('open').removeAttr('style');
              });
            } else {
              $dropdownUL.animate({ height: $dropdownUL[0].scrollHeight + 'px' }, 400, 'easeInOutCubic', function(){
                moveFlag = false;
                $dropdownUL.addClass('open').removeAttr('style');
              });
            }
            
        });

        $(window).on('scroll', function() {

        	var scrollTopp = $(window).scrollTop(),
        		navbar = $('.navbar');

        		if (scrollTopp > 0 && !navbar.is('.attached')) {
					navbar.addClass('attached');
					$bd.addClass('attached');
        		} else if (scrollTopp < 1 && navbar.is('.attached')) {
        			navbar.removeClass('attached');
        			$bd.removeClass('attached');
        		}

        })

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        function getSomeJax() {
            var t = $("#ajax_awards").data("production"),
                i = ($(".entry-content"), $("html, body"));
            return $.ajax({
                url: ajaxinfo.url,
                type: "POST",
                dataType: "html",
                data: {
                    action: "grab_awards",
                    productionID: t
                },
                success: function(t) {
                    jQhtml = $(t), jQhtml.prependTo("body").fadeIn("fast").css("display", "flex"), setTimeout(function() {
                        $.css("overflow", "hidden")
                    }, 200)
                }
            }).done(function() {
                $(".close-icon").on("click", function(t) {
                    t.preventDefault(), console.log("close this box"), $(".awards__block").fadeOut("fast"), i.removeAttr("style"), setTimeout(function() {
                        $(".awards__block").remove()
                    }, 500)
                }), scrollBarOptions = {
                    speed: 1,
                    damping: .1,
                    alwaysShowTracks: !1,
                    continuousScrolling: !0,
                    overscrollEffect: "bounce"
                }, Scrollbar.initAll(scrollBarOptions)
            }).fail(function() {}).always(function() {}), !1
        }
        $("#ajax_awards").on("click", function(e) {
            e.preventDefault(), getSomeJax()
        })
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(window).on('load', initMap);
      }
    }    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function initMap() {

    mapOptions = {
      center: {lat: 51.520883, lng: -0.106241},
      scrollwheel: false,
      // Apply the map style array to the map.
      styles: [{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"23"}]},{"featureType":"poi.attraction","elementType":"geometry.fill","stylers":[{"color":"#f38eb0"}]},{"featureType":"poi.government","elementType":"geometry.fill","stylers":[{"color":"#ced7db"}]},{"featureType":"poi.medical","elementType":"geometry.fill","stylers":[{"color":"#ffa5a8"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#c7e5c8"}]},{"featureType":"poi.place_of_worship","elementType":"geometry.fill","stylers":[{"color":"#d6cbc7"}]},{"featureType":"poi.school","elementType":"geometry.fill","stylers":[{"color":"#c4c9e8"}]},{"featureType":"poi.sports_complex","elementType":"geometry.fill","stylers":[{"color":"#b1eaf1"}]},{"featureType":"road","elementType":"geometry","stylers":[{"lightness":"100"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"},{"lightness":"100"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffd4a5"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffe9d2"}]},{"featureType":"road.local","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"weight":"3.00"}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"weight":"0.30"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#747474"},{"lightness":"36"}]},{"featureType":"road.local","elementType":"labels.text.stroke","stylers":[{"color":"#e9e5dc"},{"lightness":"30"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"visibility":"on"},{"lightness":"100"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#d2e7f7"}]}],
      zoom: 15
    };

    // Create a map object and specify the DOM element for display.
    var map = new google.maps.Map(document.getElementById('map'), mapOptions);

    var contentString = '<div id="content">'+
      '<div id="siteNotice">'+
        '</div>'+
          '<h3 id="firstHeading" class="firstHeading">The Forge</h3>'+
        '</div>'+
      '</div>';

    var local = window.location.origin;
    // var extension = '/forge';
    var extension = '';

    var image = {
      url: local + extension + '/wp-content/themes/forge/assets/images/brand/logo-pin.png',
      // This marker is 20 pixels wide by 32 pixels high.
      size: new google.maps.Size(200, 200),
      scaledSize: new google.maps.Size(50, 50),
      // The origin for this image is (0, 0).
      origin: new google.maps.Point(0, 0),
      // The anchor for this image is the base of the flagpole at (0, 32).
      anchor: new google.maps.Point(25, 25)
    };

    var marker = new google.maps.Marker({
      position: mapOptions.center,
      map: map,
      icon:image,
      title: 'The Forge'
    });

    // marker.addListener('click', function() {
    //   infowindow.open(map, marker);
    // });

    google.maps.event.addDomListener(window, 'resize', function() {
        map.setCenter(mapOptions.center);
    });

  }

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
